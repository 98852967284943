import { AuthError, isAuthError } from '@supabase/supabase-js';

export const useHandleSupabaseError = (error: AuthError): string => {
  if (!isAuthError(error)) {
    return 'composables.handleError.unknownError';
  }

  switch (error.status) {
    case 400:
      return 'composables.handleError.wrongCredentials';
    case 429:
      return 'composables.handleError.tooManyRequests';
    default:
      return 'composables.handleError.unknownError';
  }
};
