<template>
  <bb-button variant="outlined" class="w-full !px-16" @click="providerClicked">
    <div class="flex h-full w-full flex-nowrap items-center justify-center">
      <fa-icon :icon="['fab', provider]" class="text-34 mr-16 text-gray-800 dark:text-gray-50" />
      <div v-text="providerText" />
    </div>
  </bb-button>
</template>

<script lang="ts" setup>
import type { Provider } from '../../types/authentication';

const props = defineProps<{
  provider: Provider;
}>();

const emit = defineEmits<{
  (e: 'provider-click', provider: Provider): void;
}>();

const providerClicked = () => emit('provider-click', props.provider);

const providerText = computed(() => {
  switch (props.provider) {
    case 'github':
      return 'GitHub';
    case 'google':
      return 'Google';
  }
});
</script>
